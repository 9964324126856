import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
  AddInvoiceRoutine,
  ResetFetchErrorRoutine,
} from 'state/vendor/vendor.routines'
import { Flex } from 'ui-kit'
import {
  Button,
  CloseIcon,
  Text,
  DatePicker,
  Input,
  Title,
} from '@setter.inc/ui'
import { InputNumber } from 'formik-antd'
import handleValidation from './validation'
import {
  selectFetchError,
  selectVendorId,
  selectLoading,
} from 'state/vendor/vendor.selector'
import UploadInvoice from './upload-invoice'

const Container = styled.section`
  font-family: 'Basis Grotesque Pro', sans-serif;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
`

const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 3.2rem;
  margin-bottom: 1rem;
`

const HeaderClose = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
`

const FormField = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  margin: 12px 6px 12px 0;
`

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export interface InvoiceFormInitialValues {
  vendorInvoiceId: string
  subtotal: number
  tax: number
  assetId: string
  dateIssued: string
}

export interface ProInvoiceProps {
  closeHandler: () => void
  purchaseOrderId: number
}

export const AddInvoiceForm = ({
  closeHandler,
  purchaseOrderId,
}: ProInvoiceProps) => {
  const dispatch = useDispatch()

  const fetchError = useSelector(selectFetchError)
  const vendorId = useSelector(selectVendorId)
  const isLoading = useSelector(selectLoading)

  const [invoiceFile, setInvoiceFile] = useState<File | null>(null)

  const initialValues = ({} as unknown) as InvoiceFormInitialValues

  useEffect(() => {
    if (fetchError === false) {
      closeHandler()
      dispatch(ResetFetchErrorRoutine.success())
    }
  }, [fetchError, closeHandler, dispatch])

  const handleCustomSubmit = (
    values: InvoiceFormInitialValues,
    { setSubmitting }: { setSubmitting: (value: boolean) => void }
  ) => {
    setSubmitting(true)

    if (!invoiceFile) {
      return alert(
        'Oops, it appears you have not uploaded an invoice. Please try again.'
      )
    }

    const invoice = {
      ...values,
      purchaseOrderId,
      invoiceFile,
      dateIssued: new Date(values.dateIssued),
      vendorId,
    }
    dispatch(AddInvoiceRoutine.trigger(invoice))
  }

  return (
    <Container aria-live="assertive">
      <Header>
        <Title level={3}>Upload invoice</Title>
        <HeaderClose>
          <Button
            type="link"
            icon={<CloseIcon />}
            onClick={() => {
              dispatch(ResetFetchErrorRoutine.success())
              closeHandler()
            }}
          />
        </HeaderClose>
      </Header>

      <Formik
        initialValues={initialValues}
        validate={handleValidation}
        onSubmit={handleCustomSubmit}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form style={{ margin: '25px' }}>
            <Title level={4}>Add amount</Title>
            <Text strong={true}>
              Adding subtotal and tax helps us process your payment faster.
            </Text>
            <div style={{ display: 'flex' }}>
              <FormField>
                <label htmlFor="vendorInvoiceId">Invoice number</label>
                <Input
                  id="vendorInvoiceId"
                  placeholder="123456"
                  maxLength={20}
                  name="vendorInvoiceId"
                  onChange={handleChange}
                  value={values.vendorInvoiceId}
                />
              </FormField>
              <FormField>
                <label htmlFor="subtotal">Invoice subtotal</label>
                <InputNumber
                  min={0}
                  precision={2}
                  id="subtotal"
                  name="subtotal"
                  placeholder="$0"
                  value={Number(values.subtotal || 0)}
                  formatter={value => `$ ${value}`}
                  style={{ width: '100%' }}
                />
              </FormField>
            </div>
            <div style={{ display: 'flex' }}>
              <FormField>
                <label htmlFor="tax">Tax</label>
                <InputNumber
                  id="tax"
                  name="tax"
                  placeholder="$0"
                  value={Number(values.tax || 0)}
                  formatter={value => `$ ${value}`}
                  min={0}
                  precision={2}
                  style={{ width: '100%' }}
                />
              </FormField>
              <FormField>
                <label id="dateIssued" htmlFor="dateIssued">
                  Invoice Date
                </label>
                <DatePicker
                  aria-labelledby="dateIssued"
                  onChange={(_: any, dateString: string) =>
                    setFieldValue('dateIssued', dateString)
                  }
                  name="dateIssued"
                  style={{ height: 'auto' }}
                />
              </FormField>
            </div>
            <Title level={4}>Add Invoice</Title>
            <div style={{ minHeight: '125px' }}>
              <UploadInvoice
                assetId={initialValues.assetId}
                name={'assetId'}
                setFile={(file: File | null) => setInvoiceFile(file)}
                currentFile={invoiceFile}
              />
            </div>
            <Text>Please note that maximum file size is 15 MB</Text>
            <Footer>
              <Flex margin="12px 0">
                <Text type="danger">
                  {errors.vendorInvoiceId ||
                    errors.subtotal ||
                    errors.tax ||
                    errors.assetId ||
                    errors.dateIssued}
                  {fetchError &&
                    `We're sorry, we are unable to save your invoice at this time.`}
                </Text>
              </Flex>
              <Button
                onClick={e => {
                  e.preventDefault()
                  handleSubmit()
                }}
                block={true}
                loading={isSubmitting && isLoading}
                htmlType={'submit'}
              >
                Save
              </Button>
            </Footer>
          </form>
        )}
      </Formik>
    </Container>
  )
}
