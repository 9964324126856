import { getVendorData } from 'api/payments'
// TODO: get payment headings dynamically
import { headings } from './headings'

export const getVendorProfile = async () => {
  const vendorData = await getVendorData()

  return {
    data: vendorData.vendor_profile,
    headings: headings.map(heading => ({
      ...heading,
      ...{
        Cell: ({ cell: { value } }: { cell: { value: unknown } }) =>
          String(value),
      },
    })),
  }
}
