import { createRequest, REQUEST_METHOD_TYPE } from 'utils/request'
import { RawPublicKeyData } from 'types'

import { parseServerResponse } from 'utils/request/parser'
import { publicKeyParser, authStatusParser, AuthStatus } from './parser'

export const SSO_BASE_URL =
  process.env.REACT_APP_SSO_BASE_URL || 'https://staging.sso.setter.com'

export const CORE_API_BASE_URL =
  process.env.REACT_APP_CORE_API_BASE_URL || 'https://staging.api.setter.com'

export const getPublicKey = async (): Promise<RawPublicKeyData> => {
  const url = `${SSO_BASE_URL}/jwt/public`

  return createRequest({
    url,
    method: REQUEST_METHOD_TYPE.GET,
    onSuccessRequestHandler: (status, data) =>
      parseServerResponse({ success: true, data }, publicKeyParser),
    onErrorRequestHandler: (status, message) => {
      throw new Error(`An error occurred: ${message}`)
    },
  }).then((data: RawPublicKeyData) => data)
}

export const setPassword = async (
  password: string,
  token: string
): Promise<AuthStatus> => {
  const url = `${CORE_API_BASE_URL}/public/v1/pro-portal/set-password`
  return createRequest({
    url,
    method: REQUEST_METHOD_TYPE.POST,
    headers: {
      LibV2: true,
      'Content-Type': 'application/json',
    },
    data: {
      password,
      token,
    },
    onSuccessRequestHandler: (status, data) =>
      parseServerResponse({ success: true, data }, authStatusParser),
    onErrorRequestHandler: (status, message) => {
      throw new Error(`An error occurred: ${message}`)
    },
  }).then((data: AuthStatus) => data)
}

export const signin = async (
  email: string,
  password: string
): Promise<AuthStatus> => {
  const url = `${CORE_API_BASE_URL}/public/v1/pro-portal/login`
  return createRequest({
    url,
    method: REQUEST_METHOD_TYPE.POST,
    headers: {
      LibV2: true,
      'Content-Type': 'application/json',
    },
    data: {
      email,
      password,
    },
    onSuccessRequestHandler: (status, data) =>
      parseServerResponse({ success: true, data }, authStatusParser),
    onErrorRequestHandler: (status, message) => {
      throw new Error(`An error occurred: ${message}`)
    },
  }).then((data: AuthStatus) => data)
}
