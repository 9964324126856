import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SignInPage from 'pages/signin'
import SetPasswordPage from 'pages/set-password'
import PaymentsPage from 'pages/payments'
import { styled } from 'ui-kit'
import { ValidateSessionRoutine } from 'state/auth/auth.routines'
import { selectAuthState } from 'state/vendor/vendor.selector'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

const AppContainer = styled.div`
  height: 100%;
  font-family: 'Basis Grotesque Pro', sans-serif;
`
const App = () => {
  const dispatch = useDispatch()
  const auth = useSelector(selectAuthState)
  useEffect(() => {
    dispatch(ValidateSessionRoutine.trigger())
  }, [dispatch])

  return (
    <AppContainer>
      {auth.isValidating ? (
        <div />
      ) : (
        <Router>
          <Switch>
            <Route
              path="/set-password"
              render={props => <SetPasswordPage {...props} />}
            />
            {!auth.authenticated ? (
              <SignInPage />
            ) : (
              <Route path="*" render={props => <PaymentsPage />} />
            )}
          </Switch>
        </Router>
      )}
    </AppContainer>
  )
}
export default App
