import { ApplicationUserProfile } from 'api/auth/parser'
import jwt from 'jsonwebtoken'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getCachedSSOSessionToken,
  getPublicKeyData,
  setUserPassword,
  signinUser,
  clearSSOSession,
} from 'services/auth'
import {
  UnauthenticatedSessionInitRoutine,
  ValidateSessionRoutine,
  SetPasswordRoutine,
  SignInRoutine,
  SignOutRoutine,
} from './auth.routines'
import { BaseAction } from '../../types'

function* validateSessionWorker() {
  try {
    const key = yield call(getPublicKeyData)
    localStorage.setItem('pub_key', key.public_key.pub)
    const ssoToken = getCachedSSOSessionToken()

    if (!ssoToken) {
      yield put(UnauthenticatedSessionInitRoutine.trigger())
      return
    }

    if (!key) {
      throw new Error('Key not found ')
    }

    const profile = jwt.verify(
      ssoToken,
      key.public_key.pub
    ) as ApplicationUserProfile

    const { given_name, family_name, email, cellphone, exp } = profile

    if (Date.now() > 1000 * exp) {
      yield put(ValidateSessionRoutine.failure('jwt Expired'))
      throw new Error('jwt Expired')
    }

    const user = {
      first_name: given_name,
      last_name: family_name,
      email: email,
      cellphone: cellphone,
    }

    if (user) {
      yield put(
        ValidateSessionRoutine.success({ session: { user, token: ssoToken } })
      )
    } else {
      yield put(ValidateSessionRoutine.success({ user, token: undefined }))
    }
  } catch (error) {
    yield put(ValidateSessionRoutine.failure(error))
  }
}

function* setPassword(action?: BaseAction) {
  const payload = action?.payload
  const { email, password, token } = payload

  try {
    const data = yield call(setUserPassword, email, password, token)
    yield put(SetPasswordRoutine.success(data))
  } catch (error) {
    yield put(SetPasswordRoutine.failure(error))
  }
}

function* signin(action?: BaseAction) {
  const payload = action?.payload
  const { email, password } = payload

  try {
    const data = yield call(signinUser, email, password)
    yield put(SignInRoutine.success(data))
  } catch (error) {
    yield put(SignInRoutine.failure(error))
  }
}

function* signOut(action?: BaseAction) {
  try {
    const data = yield call(clearSSOSession)
    yield put(SignOutRoutine.success(data))
  } catch (error) {
    yield put(SignOutRoutine.failure(error))
  }
}

function* sessionWatcher() {
  yield takeLatest(ValidateSessionRoutine.TRIGGER, validateSessionWorker)
  yield takeLatest(SetPasswordRoutine.TRIGGER, setPassword)
  yield takeLatest(SignInRoutine.TRIGGER, signin)
  yield takeLatest(SignOutRoutine.TRIGGER, signOut)
}

export { sessionWatcher }
