import { all, call } from 'redux-saga/effects'
import { getPaymentsWatcher, addInvoiceWatcher } from './vendor/vendor.saga'
import { sessionWatcher } from './auth/auth.saga'

function* rootSaga() {
  yield all([
    call(getPaymentsWatcher),
    call(sessionWatcher),
    call(addInvoiceWatcher),
  ])
}

export default rootSaga
