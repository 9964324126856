import { BaseAction } from 'redux-actions'
import {
  LogoutRoutine,
  UnauthenticatedSessionInitRoutine,
  ValidateSessionRoutine,
  SignInRoutine,
  SignOutRoutine,
  SetPasswordRoutine,
} from './auth.routines'

export interface AuthState {
  public_key: {
    alg?: string
    aud?: string
    iss?: string
    kty?: string
    pub?: string
  }
  authenticated: boolean
  session: {
    user: {
      first_name?: string
      last_name?: string
      email?: string
      cellphone?: string
    }
    token?: string
  }
  status: string
  message?: string
  initialized: boolean
  isValidating: boolean
}

export interface AuthAction extends BaseAction {
  payload?: AuthState
}

const initialState: AuthState = {
  public_key: {},
  authenticated: false,
  session: {
    user: {},
    token: undefined,
  },
  status: '',
  message: undefined,
  initialized: false,
  isValidating: false,
}

const authReducer = (state = initialState, action: AuthAction) => {
  switch (action.type) {
    case ValidateSessionRoutine.TRIGGER:
      return {
        ...state,
        isValidating: true,
      }
    case ValidateSessionRoutine.SUCCESS:
      return {
        ...state,
        session: action?.payload?.session || { user: {} },
        authenticated: !!action?.payload?.session || false,
        initialized: true,
        isValidating: false,
      }
    case SignInRoutine.SUCCESS:
    case SetPasswordRoutine.SUCCESS:
    case SetPasswordRoutine.FAILURE:
      const status = action?.payload?.status || ''
      const message = action?.payload?.message
      const authenticated = status === 'ok' ? true : false
      return {
        ...state,
        status,
        message,
        authenticated,
        initialized: true,
        isValidating: false,
      }
    case SignOutRoutine.SUCCESS:
      return {
        ...state,
        authenticated: false,
        initialized: true,
        isValidating: false,
      }
    case ValidateSessionRoutine.FAILURE:
      return {
        ...state,
        initialized: true,
        isValidating: false,
      }
    case UnauthenticatedSessionInitRoutine.TRIGGER:
      return {
        ...state,
        authenticated: false,
        initialized: true,
        isValidating: false,
      }
    case LogoutRoutine.TRIGGER:
      return { ...initialState, initialized: true }
    default:
      return state
  }
}

export default authReducer
