import React, { useCallback } from 'react'
import { Upload, PlusIcon } from '@setter.inc/ui'
import styled from 'styled-components'

const UploadIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

interface Props {
  assetId?: string
  setFile: (file: File | null) => void
  currentFile: File | null
  name?: string
}

const UploadInvoice = ({ currentFile, setFile }: Props) => {
  const handleBeforeUpload = useCallback(
    (file: File) => {
      setFile(file)
      return false
    },
    [setFile]
  )

  const handleRemove = useCallback(() => {
    setFile(null)
  }, [setFile])

  return (
    <Upload
      listType={'picture-card'}
      beforeUpload={handleBeforeUpload}
      showUploadList={{ showPreviewIcon: false }}
      onRemove={handleRemove}
    >
      {!currentFile && (
        <UploadIndicator>
          <PlusIcon />
        </UploadIndicator>
      )}
    </Upload>
  )
}

export default UploadInvoice
