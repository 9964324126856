import React from 'react'
import { styled } from 'ui-kit'
import { BLANK, FILLED } from '../../local_constants'

const getBackground = (statusPercentage: number, dotIndex: number): string => {
  if (statusPercentage < ((dotIndex + 1) / 4) * 100) {
    return BLANK
  } else {
    return FILLED
  }
}

interface DotProps {
  width: number
  height: number
  background: string
}
const Dot = styled.header<DotProps>`
  height: ${props => props.width}em;
  width: ${props => props.width}em;
  background-color: ${props => props.background}
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #D9D9D9;
  margin-right: 0.5em;
`
interface Props {
  statusPercentage: number
}
export default (props: Props) => {
  const { statusPercentage } = props

  return (
    <div>
      <Dot
        data-testid="first-dot"
        width={0.6}
        height={0.6}
        background={getBackground(statusPercentage, 0)}
      />
      <Dot
        data-testid="second-dot"
        width={0.6}
        height={0.6}
        background={getBackground(statusPercentage, 1)}
      />
      <Dot
        data-testid="third-dot"
        width={0.6}
        height={0.6}
        background={getBackground(statusPercentage, 2)}
      />
      <Dot
        data-testid="fourth-dot"
        width={0.6}
        height={0.6}
        background={getBackground(statusPercentage, 3)}
      />
    </div>
  )
}
