import { Action } from 'redux-actions'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getVendorProfile } from 'services/vendor'
import { AddInvoicePayloadType } from 'types'
import { GetVendorProfileRoutine, AddInvoiceRoutine } from './vendor.routines'
import { addVendorInvoice } from 'api/payments'

function* getPaymentsWorker() {
  try {
    yield put(GetVendorProfileRoutine.request())
    // @ts-ignore
    const data = yield call(getVendorProfile)
    yield put(GetVendorProfileRoutine.success(data))
  } catch (error) {
    yield put(GetVendorProfileRoutine.failure(error))
  }
}

function* getPaymentsWatcher() {
  yield takeLatest(GetVendorProfileRoutine.TRIGGER, getPaymentsWorker)
}

function* addInvoiceWorker(
  action: Action<AddInvoicePayloadType & { vendorId: number }>
) {
  try {
    yield put(AddInvoiceRoutine.request())
    yield call(addVendorInvoice, action.payload as AddInvoicePayloadType)
    yield put(AddInvoiceRoutine.success())

    // instead of updating the single purchase order, fetch all the data (FOR NOW)
    const data = yield call(getVendorProfile)
    yield put(GetVendorProfileRoutine.success(data))
  } catch (error) {
    yield put(AddInvoiceRoutine.failure())
  }
}

function* addInvoiceWatcher() {
  yield takeLatest(AddInvoiceRoutine.TRIGGER, addInvoiceWorker)
}

export { getPaymentsWatcher, addInvoiceWatcher }
