import React, { MouseEvent, ChangeEvent, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import logo from 'assets/setter-logo-navy.svg'
import heroImage from 'assets/login_hero.svg'
import { Button } from '@setter.inc/ui'
import { styled, colors } from 'ui-kit'
import { SignInRoutine } from '../../state/auth/auth.routines'
import { ApplicationState } from 'state/store'

const Container = styled.div`
    display: flex
    justify-content: start
    flex-direction: column

    .wrapper {
        display: flex
        height: 100vh
        width: 100%
        @media screen and (max-width: 1025px) {
            flex-direction: column
        }
    }

    .heroWrapper {
        width: 100vh
        display: flex
        flex-direction: column
        justify-content: start
        align-items: center
        flex: 1 0 auto
        @media screen and (min-width: 1025px) {
            width: 50%
        }
        @media screen and (max-width: 1025px) {
            display: none
        }
        @media screen and (max-width: 1025px) {
            padding-bottom: (48/16)em
        }
    }

    .logoLeft {
        width: 100%
        height: auto
        margin-left: 8%
        margin-top: 5%
        margin-bottom: 5%
        @media screen and (max-width: 1281px) {
            width: 100%
            margin-left: 5%
        }
    }

    .logoRight {
        width: 100%
        height: auto
        margin-left: 5%
        margin-top: 1%
        margin-bottom: 1%
        background-color: white
        @media screen and (min-width: 1025px) {
           display: none
        }
    }

    .sliderWrapper {
        width: 100%
    }

    .slide {
        width: 100%
        height: 100%
        display: flex
        flex-direction: column
        justify-content: space-around
        align-items: center
    }

    .mainImage {
        width: 50%
        @media screen and (max-width: 1281px) {
            width: auto
            max-height: 208px
        }
    }

    .heading {
        font-family: Chap Regular
        font-size: 2em
        text-align: center
        color: #3F5870
        margin-top: 1em
        margin-bottom: 0.5em
        @media screen and (max-width: 1025px) {
            margin-bottom: 0.125em
        }
    }

    .login {
        font-family: Chap Regular
        font-size: 2em
        text-align: center
        color: #FFFFFF
        margin-bottom: 0.5em
        @media screen and (max-width: 1025px) {
            display: none
            margin-bottom: 0.125em
        }
    }

    .welcome {
        font-family: Chap Regular
        font-size: 2em
        text-align: center
        color: #FFFFFF
        margin-top: 0.5em
        @media screen and (min-width: 1025px) {
            display: none
            margin-bottom: 3.125em
        }
    }


    .slideText {
        color: #4A627C;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        @media screen and (max-width: 361px) {
            background-color: red
            max-width: 60%
        }
    }

    .formWrapper {
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        background-color: #344e63
        color: #FFFFFF
        flex: 1 0 auto
        padding-top: 1em
        @media screen and (min-width: 1025px) {
            width: 50%
        }
    }

    .formBox {
        @media screen and (max-width: 481px) {
            width: 80%
        }
    }

    .form {
        width: 100%
    }
    .formFields {
        box-sizing: border-box;
        height: 50px;
        width: 375px;
        border: 1px solid #8595A1;
        border-radius: 4px;
        background-color: ${colors.white};
        font-size: 1em;
        color: ${colors.black}
        outline:none;
        padding: 0.5em;
        margin: 0em 0 0.25em
        @media screen and (max-width: 481px) {
            width: 100%
        }
    }

    .subHeading {
        height: 24px;
        width: 365px;
        color: ${colors.white};
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        margin-bottom: 2em
        @media screen and (max-width: 481px) {
            width: 80%
        }
    }

    .error {
        font-size: 16px
        color: red
    }

    .text {
        font-size: 0.75em
        margin: 1em 0 0.25em
    }

    .bottomText {
      margin: 0em 0 0.25em
    }

    .link {
        font-size: 1em
        color: #fff
        &:hover
            color: #21bd5a
    }

    .label {
        font-family: Basis Grotesque Pro
        font-size: 0.75em
        text-align: center
        align-self: center
        margin: 1em 0 0.25em

    }

    .submit {
        text-align: center;
        width: 160px
        margin: 30px auto
    }

    .input {
        display: flex;
        flex-direction: column
        margin: 1em
        align-items: center;
    }

    .inputFields {
        width: 100%
    }
`

const SignIn = React.memo(() => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const results = useSelector(state => state) as ApplicationState

  const handleSubmit = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault()
      dispatch(SignInRoutine.trigger({ email, password }))
    },
    [dispatch, email, password]
  )

  const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setEmail(e.target.value)
  }, [])

  const onPasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setPassword(e.target.value)
  }, [])

  return (
    <Container>
      <div className="wrapper">
        <div className="heroWrapper">
          <div className="logoLeft">
            <img src={logo} alt="logo" />
          </div>
          <div className="sliderWrapper">
            <div className="slide">
              <img alt={'setter'} src={heroImage} className="mainImage" />
              <div className="heading">Welcome to Pro Portal!</div>
              <div className="slideText">
                The one area to view and manage your Setter payments.
              </div>
            </div>
          </div>
        </div>
        <div className="logoRight">
          <img src={logo} alt="logo" />
        </div>
        <div className="formWrapper">
          <div className="login">Log In</div>
          <div className="welcome">Welcome to Pro Portal!</div>

          <div className="subHeading">
            Please enter details below to access your account.
          </div>

          <form className="formBox" data-testid="form">
            <div className="form">
              <div className="inputFields">
                <div className="text">Email</div>
                <input
                  value={email}
                  onChange={onEmailChange}
                  className="formFields"
                  placeholder="pro@gmail.com"
                  id="email-id"
                  data-testid="email"
                />
              </div>

              <div className="inputFields">
                <div className="text">Password</div>
                <input
                  value={password}
                  onChange={onPasswordChange}
                  type="password"
                  className="formFields"
                  placeholder="password"
                  id="email-id"
                  data-testid="password"
                />
                {results.auth.status === 'error' && (
                  <div className="text bottomText error">
                    {'Invalid info. ' + results.auth.message}
                  </div>
                )}
              </div>
            </div>
            <div className="submit">
              <Button
                onClick={handleSubmit}
                type="primary"
                data-testid="sign-in-button"
              >
                Continue
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  )
})

export default SignIn
