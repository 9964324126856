import * as rt from 'runtypes'

export const applicationUserProfileParser = rt.Record({
  sub: rt.String,
  name: rt.String,
  given_name: rt.String,
  family_name: rt.String,
  picture: rt.String,
  email: rt.String,
  email_verified: rt.String,
  locale: rt.String,
  hd: rt.String,
  cellphone: rt.String,
  exp: rt.Number,
  id: rt.Number,
})

export const publicKeyParser = rt.Record({
  kty: rt.String,
  alg: rt.String,
  pub: rt.String,
  aud: rt.String,
  iss: rt.String,
})

export const authStatusParser = rt.Record({
  status: rt.String,
  token: rt.String,
})

export const setPasswordPayloadParser = rt.Record({
  payload: rt.Record({
    password: rt.String,
    token: rt.String,
  }),
})

export type PublicKey = rt.Static<typeof publicKeyParser>
export type ApplicationUserProfile = rt.Static<
  typeof applicationUserProfileParser
>
export type AuthStatus = rt.Static<typeof authStatusParser>
export type SetPasswordPayload = rt.Static<typeof setPasswordPayloadParser>
