import React, {
  FormEvent,
  useEffect,
  useCallback,
  useState,
  ChangeEvent,
} from 'react'
import { RouteChildrenProps } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import logo from 'assets/setter-logo-navy.svg'
import heroImage from 'assets/login_hero.svg'
import { Button } from '@setter.inc/ui'
import { styled } from 'ui-kit'
import queryString from 'query-string'
import { SetPasswordRoutine } from '../../state/auth/auth.routines'
import { ApplicationState } from 'state/store'
import { validator } from 'utils/auth'

const Container = styled.div`
    display: flex
    justify-content: start
    flex-direction: column

    .wrapper {
        display: flex
        height: 100vh
        width: 100%
        flex-direction: column
        @media screen and (max-width: 1025px) {
            flex-direction: column
        }
    }

    .logo {
        display: flex
        width: 100%
        padding-left: 1em
        padding-top: 0.5em
        padding-bottom: 0.5em
        background-color: white
    }

    .sliderWrapper {
        width: 100%
    }

    .slide {
        width: 100%
        height: 100%
        display: flex
        flex-direction: column
        justify-content: space-around
        align-items: center
    }

    .mainImage {
        max-height: 170px
        @media screen and (max-width: 769px) {
            display: none
        }
    }

    .heading {
        font-family: Chap Regular
        font-size: 2em
        text-align: center
        color: #3F5870
        margin-bottom: 1em
        @media screen and (max-width: 1025px) {
            margin-bottom: 0.125em
        }
    }

    .login {
        font-family: Chap Regular
        font-size: 2em
        text-align: center
        color: #FFFFFF
        margin-bottom: 0.5em
        @media screen and (max-width: 1025px) {
            display: none
            margin-bottom: 0.125em
        }
    }

    .welcome {
        font-family: Chap Regular
        font-size: 2em
        text-align: center
        color: #344E63
        margin-bottom: 0.5em
        @media screen and (max-width: 481px) {
            font-size: 1.3em
        }
        @media screen and (min-width: 1025px) {
            margin-bottom: 0.250em
        }
    }

    .slideText {
        font-family: Chap Regular
        font-size: 1.1em
        margin: 1em 0 0.25em
        width: 80%
        text-align: center
        color: #3F5870
        @media screen and (max-width: 361px) {
            background-color: red
            max-width: 60%
        }
    }

    .formWrapper {
        width: 100%
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        background-color: #F0F2F5
        color: #FFFFFF
        flex: 1 0 auto
        padding-top: 1em
        @media screen and (min-width: 1025px) {
            width: 100%
        }
    }

    .formBox {
        @media screen and (max-width: 481px) {
            width: 80%
        }
    }

    .form {
        width: 100%
    }
    .formFields {
        box-sizing: border-box;
        height: 50px;
        width: 375px;
        border: 1px solid #8595A1;
        border-radius: 4px;
        background-color: #FFFFFF;
        font-size: 1em;
        outline:none;
        padding: 0.5em;
        margin: 0em 0 0.25em
        @media screen and (max-width: 481px) {
            width: 100%
        }
    }

    .errorInput {
        border-color: red
    }

    .subHeading {
        height: 24px;
        width: 365px;
        color: #344E63
        font-size: 16px;
        line-height: 15px;
        text-align: center;
        margin-bottom: 2em
        @media screen and (max-width: 481px) {
            font-size: 12px;
            width: 80%
        }
    }

    .text {
        color: #344E63
        font-size: 0.8em
        margin: 1em 0 0.25em
    }

    .error {
      color: red
    }

    .bottomText {
      margin: 0em 0 0.25em
    }


    .link {
        font-size: 1em
        color: #fff
        &:hover
            color: #21bd5a
    }

    .label {
        font-family: Basis Grotesque Pro
        font-size: 0.75em
        text-align: center
        align-self: center
        margin: 1em 0 0.25em

    }

    .submit {
        width: 160px
        margin: 30px auto
    }

    .input {
        display: flex;
        flex-direction: column
        margin: 1em
        align-items: center;
    }

    .inputFields {
        width: 100%
    }

    .tooltip {
        opacity: 70%
        border-radius: 4px;
        background-color: #D2E1E5;
        font-family: Basis Grotesque Pro Regular;
        padding: 1em;
        font-size: 14px;
        color: #344E63;
        @media screen and (min-width: 769px) {
            display: none
        }
    }

    .tooltipArrow {
        opacity: 70%
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent
        border-top: 10px solid #D2E1E5;
        @media screen and (min-width: 769px) {
              display: none
        }
    }
    input:read-only {
        background-color: lightgray;
    }

    .tooltipRight {
        opacity: 70%
        color: black
        position: absolute
        display: inline-flex
        border-radius: 4px;
        background-color: #D2E1E5;
        font-family: Basis Grotesque Pro Regular;
        padding: 1.5em;
        font-size: 14px;
        color: #344E63;
        margin-left: 35px;
        @media screen and (max-width: 769px) {
            display: none
        }
    }

    .tooltipRightArrow {
        opacity: 70%
        position: absolute
        margin-top: 15px
        margin-left: 25px
        display: inline-flex
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent
        border-right: 10px solid #D2E1E5;
        @media screen and (max-width: 769px) {
            display: none
        }
    }

    .emailText {
      color: #3F5870
      font-weight: bold
      margin-top: 1em
      margin-bottom: 0.5em
    }
`

const SetPassword = (props: RouteChildrenProps) => {
  const dispatch = useDispatch()
  const results = useSelector(state => state) as ApplicationState
  const params = queryString.parse(props.location.search) as { token: string }
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [validPassword, setValidPassword] = useState(true)
  const validation = validator(password, confirmPassword) ? true : false
  const [token, setToken] = useState(`${params.token}`)

  if (results.auth.authenticated) {
    props.history.replace({ pathname: '/' })
  }

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (validation) {
        dispatch(SetPasswordRoutine.trigger({ email, password, token }))
      } else {
        setValidPassword(false)
      }
    },
    [validation, dispatch, email, password, token]
  )

  const onPasswordChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      setValidPassword(true)
      await setPassword(e.target.value)
    },
    []
  )

  const onConfirmPasswordChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      setValidPassword(true)
      await setConfirmPassword(e.target.value)
    },
    []
  )

  useEffect(() => {
    try {
      const obj: { email: string; token: string } = JSON.parse(
        atob(params.token)
      ) as { email: string; token: string }
      setEmail(obj.email)
      setToken(obj.token)
    } catch (ex) {
      dispatch(
        SetPasswordRoutine.failure({
          status: 'error',
          message: 'Please contact setter support.',
        })
      )
    }
  }, [dispatch, email, params, token])

  const ToolTip = () => (
    <div>
      Password must contain: <br />
      &#183; &nbsp; At least 5 characters <br />
      &#183; &nbsp; At least one number (0-9)
    </div>
  )

  return (
    <Container>
      <div className="wrapper">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="formWrapper">
          <img alt={'setter'} src={heroImage} className="mainImage" />
          <div className="welcome">Hello, Welcome to Pro Portal!</div>

          <div className="subHeading">
            Please enter details below to access your account.
          </div>

          <div className="tooltip">
            <ToolTip />
          </div>
          <div className="tooltipArrow" />

          <form onSubmit={handleSubmit} className="formBox">
            <div className="form">
              <div className="inputFields">
                <div
                  className="emailText"
                  placeholder="Email"
                  id="email"
                  data-testid="email"
                >
                  {email}
                </div>
              </div>
              <div className="inputFields">
                <div className="text">New password</div>
                <div
                  style={{
                    position: 'relative',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <input
                    value={password}
                    onChange={onPasswordChange}
                    type="password"
                    className={
                      validPassword ? 'formFields' : 'formFields errorInput'
                    }
                    placeholder="password"
                    id="password"
                  />
                  <div className="tooltipRightArrow" />
                  <div className="tooltipRight">
                    <ToolTip />
                  </div>
                </div>
              </div>
              <div className="inputFields">
                <div className="text">Confirm password</div>
                <input
                  value={confirmPassword}
                  onChange={onConfirmPasswordChange}
                  type="password"
                  className={
                    validPassword ? 'formFields' : 'formFields errorInput'
                  }
                  placeholder="Password"
                  id="confirmpassword"
                />
                {results.auth.status === 'error' && (
                  <div className="text bottomText error">
                    {'Invalid info. ' + results.auth.message}
                  </div>
                )}
              </div>
              <div className="submit">
                <Button type="primary" data-testid="sign-in-button">
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Container>
  )
}

export default SetPassword
