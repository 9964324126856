export const PHONE_NUMBER_FORMAT = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  '-',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
export const FILLED = '#FF4856'
export const BLANK = '#FFFFFF'
