import { load } from 'react-cookies'

export enum REQUEST_METHOD_TYPE {
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
}

export interface IRequest<H extends {}, T, R> {
  data?: T
  headers?: H
  method: REQUEST_METHOD_TYPE
  onSuccessRequestHandler: (statusCode: number, data: unknown) => R
  onErrorRequestHandler: (statusCode: number, message: string) => R
  url: string
}

export interface IAuthenticationHeader {
  Authorization: string
}

const getAuthenticationHeader = (): IAuthenticationHeader | undefined => {
  const authToken = load('pro_access_token')
  if (!authToken) {
    return
  }

  return {
    Authorization: authToken,
  }
}

export const createRequest = async <H extends {}, T, R>({
  data,
  headers,
  method,
  onSuccessRequestHandler,
  onErrorRequestHandler,
  url,
}: IRequest<H, T, R>): Promise<R> => {
  return fetch(url, {
    method,
    headers,
    body: JSON.stringify(data),
  })
    .then(async (response: Response) => {
      // return onSuccessRequestHandler(response.status, response.json())
      return response.json()
    })
    .catch(err => {
      if (err.response) {
        return onErrorRequestHandler(
          err.response.status,
          err.response.data as string
        )
      }

      return onErrorRequestHandler(200, 'Parsing Validation error')
    })
}

export const createAuthenticatedRequest = async <H extends {}, T, R>({
  data,
  headers,
  method,
  onSuccessRequestHandler,
  onErrorRequestHandler,
  url,
}: IRequest<H, T, R>): Promise<R> => {
  return createRequest({
    data,
    headers: {
      ...headers,
      ...getAuthenticationHeader(),
      'Content-Type': 'application/json',
    },
    method,
    onSuccessRequestHandler,
    onErrorRequestHandler,
    url,
  })
}

export default {
  REQUEST_METHOD_TYPE,
  createRequest,
}

export const createAuthenticatedFormDataRequest = ({
  data,
  url,
}: {
  data: FormData
  url: string
}) => {
  return fetch(url, {
    method: 'POST',
    body: data,
    headers: {
      ...getAuthenticationHeader(),
    },
  }).then((response: Response) => {
    if (response.ok) {
      return response.json()
    } else {
      throw new Error()
    }
  })
}
