import React from 'react'
import docLogo from '../../assets/doc_icon.svg'
import AddInvoice from '../../components/Add-invoice'
import ProgressCircles from '../../components/ProgressCircle'

enum Status {
  NO_INVOICE = 'NO_INVOICE',
  CANCELLED = 'CANCELLED',
  PROBLEM = 'PROBLEM',
  PROCESSING = 'PROCESSING',
  FINALIZED = 'FINALIZED',
  PAID = 'PAID',
}

export const getStatusPercentage = (status: string) => {
  switch (status) {
    case Status.NO_INVOICE:
      return 0
    case Status.CANCELLED:
      return 0
    case Status.PROBLEM:
      return 25
    case Status.PROCESSING:
      return 50
    case Status.FINALIZED:
      return 75
    case Status.PAID:
      return 100
    default:
      return 0
  }
}

const getStatusText = (status: string) => {
  switch (status) {
    case Status.NO_INVOICE:
      return 'No Invoice'
    case Status.CANCELLED:
      return 'Cancelled'
    case Status.PROBLEM:
      return 'Problem'
    case Status.PROCESSING:
      return 'Processing'
    case Status.FINALIZED:
      return 'Finalized'
    case Status.PAID:
      return 'Paid'
    default:
      return ''
  }
}

export const getStatusInfoView = (record: any) => {
  const { status, statusDescription, poId } = record
  let action
  switch (status) {
    case Status.NO_INVOICE:
      action = (
        <div style={{ display: 'flex', flex: 2 }}>{AddInvoice(poId)}</div>
      )
      break
    case Status.PROBLEM:
    case Status.CANCELLED:
      action = (
        <div style={{ display: 'flex', flex: 2 }}>{statusDescription}</div>
      )
      break
    case Status.PROCESSING:
    case Status.FINALIZED:
    case Status.PAID:
    default:
      action = (
        <div style={{ display: 'flex', flex: 2 }}>
          <div style={{ marginRight: 'auto' }}>{statusDescription}</div>
          <img alt={'Invoice'} style={{ marginLeft: '10px' }} src={docLogo} />
        </div>
      )
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <ProgressCircles statusPercentage={getStatusPercentage(status)} />
        <div>{getStatusText(status)}</div>
      </div>
      {action}
    </div>
  )
}

export const getResponsiveStatusInfoView = (record: any) => {
  const { status } = record
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
        <ProgressCircles statusPercentage={getStatusPercentage(status)} />
        <div style={{ display: 'flex' }}>{getStatusText(status)}</div>
      </div>
    </div>
  )
}

export const headings: any[] = [
  {
    title: 'Job Name',
    dataIndex: 'title',
    key: 'Job Name',
    sorter: (a: { title: string }, b: { title: string }) =>
      a.title.localeCompare(b.title),
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'Address',
    sorter: (a: { address: string }, b: { address: string }) =>
      a.address.localeCompare(b.address),
  },
  {
    title: 'Completion date',
    dataIndex: 'completedOn',
    key: 'Completion date',
    sorter: (a: { completedOn: string }, b: { completedOn: string }) =>
      Date.parse(a.completedOn) - Date.parse(b.completedOn),
  },
  {
    title: 'Total Job Cost (Incl. tax)',
    dataIndex: 'total',
    key: 'Total Job Cost (Incl. tax)',
    sorter: (a: { total: string }, b: { total: string }) =>
      Number(a.total) - Number(b.total),
  },
  {
    title: 'PO #',
    dataIndex: 'purchaseOrderId',
    key: 'PO #',
    sorter: (a: { purchaseOrderId: string }, b: { purchaseOrderId: string }) =>
      Number(a.purchaseOrderId) - Number(b.purchaseOrderId),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'Status',
    width: '30%',
    sorter: (a: { status: string }, b: { status: string }) =>
      getStatusPercentage(a.status) - getStatusPercentage(b.status),
    render: (_: any, record: any) => getStatusInfoView(record),
    responsiveRender: getResponsiveStatusInfoView,
  },
]
