import {
  createAuthenticatedRequest,
  createAuthenticatedFormDataRequest,
  REQUEST_METHOD_TYPE,
} from 'utils/request'
import { VendorApiResponse, VendorData, AddInvoicePayloadType } from 'types'
import { parseServerResponse } from 'utils/request/parser'
import { vendorDataParser } from './parser'
import { getUserProfile } from 'services/auth'

const baseUrl =
  process.env.REACT_APP_PAYMENT_HUB_API_URL ||
  'https://staging.payment-hub-api.setter.com'

export const getVendorData = async (): Promise<VendorData> => {
  const { id } = getUserProfile()

  const url = `${baseUrl}/vendor-profiles/${id}`

  return createAuthenticatedRequest({
    url,
    method: REQUEST_METHOD_TYPE.GET,
    onSuccessRequestHandler: (_, data) =>
      parseServerResponse(data, vendorDataParser),
    onErrorRequestHandler: (_, message) => {
      throw new Error(`An error occurred: ${message}`)
    },
    // @ts-ignore
  }).then((data: VendorApiResponse) => {
    return data.data
  })
}

export const addVendorInvoice = async (
  payload: AddInvoicePayloadType
): Promise<unknown> => {
  const {
    purchaseOrderId,
    vendorInvoiceId,
    subtotal,
    tax,
    dateIssued,
  } = payload
  const url = `${baseUrl}/purchase-orders/${purchaseOrderId}/vendor-invoices`

  const formattedPayload = {
    vendorInvoiceId,
    subtotal,
    tax,
    assetId: undefined,
    dateIssued,
  }

  const formData = new FormData()
  formData.append('files', payload.invoiceFile)
  formData.append('assetType', 'vendor-invoice')

  const {
    files: [asset],
  } = await createAuthenticatedFormDataRequest({
    url: `${baseUrl}/assets`,
    data: formData,
  })

  formattedPayload.assetId = asset?.id

  return createAuthenticatedRequest({
    url,
    method: REQUEST_METHOD_TYPE.POST,
    data: formattedPayload,
    onSuccessRequestHandler: (_, data) => data,
    onErrorRequestHandler: (_, message) => {
      throw new Error(`An error occurred: ${message}`)
    },
  })
}
