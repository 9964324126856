import * as rt from 'runtypes'

export const headingsItemParser = rt.Record({
  Header: rt.String,
  accessor: rt.String,
})

export const headingsParser = rt.Array(headingsItemParser)

const addressParser = rt.Record({
  streetName: rt.String,
  streetNumber: rt.String,
  unitNumber: rt.String.Or(rt.Undefined),
  postalCode: rt.String,
  country: rt.String,
  region: rt.String,
  city: rt.String,
})

const invoiceParser = rt.Record({
  title: rt.String,
  purchaseOrderId: rt.Number,
  total: rt.String,
  completedOn: rt.String,
  isPaidByClient: rt.Boolean,
  address: addressParser,
  poId: rt.Number,
  status: rt.String,
  description: rt.String,
})

const nextPaymentDateParser = rt.Record({
  startDate: rt.String,
  endDate: rt.String,
  paymentDate: rt.String,
  amount: rt.Number,
})

const profileParser = rt.Record({
  vendorName: rt.String,
  vendorId: rt.Number,
  invoices: rt.Array(invoiceParser),
  nextPaymentData: nextPaymentDateParser,
})

export const vendorDataParser = rt.Record({
  vendor_profile: profileParser,
})
