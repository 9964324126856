import { ClientAddress, TableDisplayData, Invoice } from 'types'
import dayjs from 'dayjs'

export const formatDate = (date: string | number | Date): string => {
  return dayjs(date).format('MM/DD/YY')
}

export const formatAddress = (item: ClientAddress) =>
  `${item.streetNumber} ${item.streetName}\n${item.city}, ${item.region} ${item.postalCode}`

export const mapInvoicesToTableData = (
  invoices: Invoice[]
): TableDisplayData[] =>
  invoices.map(invoice => ({
    ...invoice,
    address: formatAddress(invoice.address),
    completedOn: formatDate(invoice.completedOn),
  }))
