export enum PrefixesEnum {
  GetVendorProfile = 'GET_VENDOR_PROFILE',
  AddInvoice = 'ADD_INVOICE',
  LoadPayments = 'LOAD_PAYMENTS',
  Logout = 'LOGOUT',
  UnauthenticatedSessionInit = 'UNAUTHENTICATED_SESSION_INIT',
  ValidateSession = 'VALIDATE_SESSION',
  SetPassword = 'SET_PASSWORD',
  SignIn = 'SIGN_IN',
  SignOut = 'SIGN_OUT',
  ResetFetchError = 'RESET_FETCH_ERROR',
}
