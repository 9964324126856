import { createRoutine } from 'redux-saga-routines'
import { PrefixesEnum } from '../prefixes'

const LogoutRoutine = createRoutine(PrefixesEnum.Logout)
const UnauthenticatedSessionInitRoutine = createRoutine(
  PrefixesEnum.UnauthenticatedSessionInit
)
const ValidateSessionRoutine = createRoutine(PrefixesEnum.ValidateSession)
const SetPasswordRoutine = createRoutine(PrefixesEnum.SetPassword)
const SignInRoutine = createRoutine(PrefixesEnum.SignIn)
const SignOutRoutine = createRoutine(PrefixesEnum.SignOut)

export {
  LogoutRoutine,
  UnauthenticatedSessionInitRoutine,
  ValidateSessionRoutine,
  SetPasswordRoutine,
  SignInRoutine,
  SignOutRoutine,
}
