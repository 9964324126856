import { createSelector } from 'reselect'
import { ApplicationState as State } from 'state/store'
import { VendorRemoteDataState } from './vendor.reducer'

const selectState = (state: State) => state

export const selectAuthState = createSelector(selectState, state => state.auth)

export const selectVendorData = createSelector(
  selectState,
  state => state.vendor
)

export const selectVendorHeadings = createSelector(
  selectVendorData,
  vendor => vendor?.headings
)

export const selectVendorProfile = createSelector(
  selectVendorData,
  vendor => vendor?.profile
)

export const selectFetchError = createSelector<
  State,
  VendorRemoteDataState,
  boolean | null
>(selectVendorData, state => state.fetchError)

export const selectVendorId = createSelector(
  selectVendorData,
  state => state.profile.vendorId
)

export const selectInvoices = createSelector(
  selectVendorData,
  state => state.profile.invoices
)

export const selectLoading = createSelector(
  selectVendorData,
  state => state.loading
)
