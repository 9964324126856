import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled, colors } from 'ui-kit'
import { GetVendorProfileRoutine } from 'state/vendor/vendor.routines'
import { Header } from 'components'
import {
  selectVendorProfile,
  selectVendorHeadings,
  selectInvoices,
} from 'state/vendor/vendor.selector'
import { mapInvoicesToTableData } from 'utils/table'
import Banner from 'components/Banner'
import { getUserProfile } from 'services/auth'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Table } from '@setter.inc/ui'
import { getStatusPercentage } from 'services/headings'

dayjs.extend(utc)

const Container = styled.div`
  background-color: ${colors.offWhite};
  height: auto;
  margin: 12px 0;
`

const TableWrapper = styled.div`
  margin: 5%;
`

export default () => {
  const dispatch = useDispatch()

  const profile = useSelector(selectVendorProfile)
  const headings = useSelector(selectVendorHeadings)
  const invoices = useSelector(selectInvoices)

  const invoiceData = mapInvoicesToTableData(invoices || [])
  const loading = !(invoices.length > 0)

  useEffect(() => {
    dispatch(
      GetVendorProfileRoutine.trigger({
        vendor_id: profile.vendorId,
      })
    )
  }, [dispatch, profile.vendorId])

  const { startDate, endDate, paymentDate, amount } = profile?.nextPaymentData
  const userProfile = getUserProfile() as { name: string }
  const earningsDateRange = `${dayjs(startDate)
    .utc()
    .format('MMMM D')} - ${dayjs(endDate)
    .utc()
    .format('D')}`
  const nextPaymentDateString = dayjs(paymentDate)
    .utc()
    .format('MMMM D, YYYY')

  const sorter = (a: { status: string }, b: { status: string }) =>
    getStatusPercentage(a.status) - getStatusPercentage(b.status)

  return (
    <Container>
      <Header />
      <>
        {profile?.invoices ? (
          <>
            <Banner
              earnings={amount}
              name={userProfile.name}
              earningsDateRange={earningsDateRange}
              nextPaymentDateString={nextPaymentDateString}
              loading={loading}
            />
            <TableWrapper>
              <Table
                responsive={true}
                columns={headings}
                dataSource={invoiceData}
                responsiveSorter={sorter}
              />
            </TableWrapper>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </>
    </Container>
  )
}
