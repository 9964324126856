import { getPublicKey, setPassword, signin } from 'api/auth'
import { RawPublicKeyData } from 'types'
import { load, remove, save } from 'react-cookies'
import jwt from 'jsonwebtoken'

export const getPublicKeyData = async () => {
  const publicKeyData: RawPublicKeyData = await getPublicKey()

  return {
    public_key: publicKeyData,
  }
}

export const clearSSOSession = (): void => {
  remove('pro_access_token', { domain: 'pro.setter.com' })
}

export const getCachedSSOSessionToken = (): string | null => {
  return load('pro_access_token')
}

export const getUserProfile = () => {
  const token = getCachedSSOSessionToken() || ''
  const key = localStorage.getItem('pub_key') || ''

  return jwt.verify(token, key) as { id: number; name: string }
}

export const saveSSOSessionToken = (value: string): void => {
  return save('pro_access_token', value, { domain: 'pro.setter.com' })
}

export const setUserPassword = async (
  email: string,
  password: string,
  token: string
) => {
  const authData = (await setPassword(password, token)) as {
    token: string
    status: string
  }

  saveSSOSessionToken(authData.token)

  return authData
}

export const signinUser = async (email: string, password: string) => {
  const authData = (await signin(email, password)) as {
    token: string
    status: string
  }

  saveSSOSessionToken(authData.token)

  return authData
}
