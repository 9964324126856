import React from 'react'
import { Popover } from '@setter.inc/ui'

interface FormpopupProps {
  onVisibleChange: (visible: boolean) => void
  content: React.ReactNode
  visible: boolean
  triggerContent: React.ReactElement<unknown>
}

export default (props: FormpopupProps) => {
  return (
    <Popover
      trigger="click"
      placement="leftBottom"
      visible={props.visible}
      onVisibleChange={props.onVisibleChange}
      content={props.content}
    >
      {props.triggerContent}
    </Popover>
  )
}
