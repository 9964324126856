export const validator = (
  password: string,
  confirmPassword: string
): boolean => {
  if (
    password === confirmPassword &&
    password.length >= 5 &&
    /\d/.test(password)
  ) {
    return true
  }
  return false
}
