import { InvoiceFormInitialValues } from './add-invoice-form'

export interface FormErrors {
  vendorInvoiceId?: string
  subtotal?: string
  tax?: string
  dateIssued?: string
}

export default (values: InvoiceFormInitialValues) => {
  const errors: FormErrors = {}

  if (!values.vendorInvoiceId) {
    errors.vendorInvoiceId = 'Please enter a valid invoice number'
  }

  if (values.subtotal === undefined || values.subtotal <= 0) {
    errors.subtotal = 'Please enter a valid subtotal'
  }

  if (values.tax === undefined || values.tax < 0) {
    errors.tax = 'Please enter a valid tax value'
  }

  if (!values.dateIssued || values.dateIssued === '') {
    errors.dateIssued = 'Please enter a valid date'
  }

  return errors
}
