import React from 'react'
import { styled, colors } from 'ui-kit'
import { Card, Skeleton } from '@setter.inc/ui'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 28px;
  margin-top: 20px;
`

const PaymentStatusContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
`

interface Props {
  name: string
  earningsDateRange: string
  earnings: number
  nextPaymentDateString: string
  loading: boolean
}

export default (props: Props) => {
  return (
    <Container>
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        Hello, {props.name}!
      </div>
      <Card
        style={{
          minWidth: 520,
          minHeight: 181,
          marginTop: 16,
          border: `1px solid ${colors.primary}`,
          borderRadius: '4px',
        }}
      >
        <Skeleton active={true} loading={props.loading} paragraph={{ rows: 2 }}>
          <PaymentStatusContainer>
            <div style={{ fontSize: '16px' }}>
              Your earnings between {props.earningsDateRange}
            </div>
            <div style={{ fontSize: '38px' }}>${props.earnings.toFixed(2)}</div>
            <div style={{ fontSize: '16px' }}>
              {`Next payment is scheduled on ${props.nextPaymentDateString}`}
            </div>
          </PaymentStatusContainer>
        </Skeleton>
      </Card>
    </Container>
  )
}
