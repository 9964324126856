import * as rt from 'runtypes'

const errorServerResponseParser = rt.Record({
  success: rt.Literal(false),
  data: rt.String,
})

const successfullServerResponseParser = rt.Record({
  success: rt.Literal(true),
  data: rt.Unknown,
})

const serverResponseParser = rt.Union(
  errorServerResponseParser,
  successfullServerResponseParser
)

export const parseServerResponse = <T>(
  serverResponse: unknown,
  dataParser: rt.Runtype<T>
): T => {
  const result = serverResponseParser.check(serverResponse)

  if (result.success) {
    return dataParser.check(result.data)
  } else {
    throw new Error(`Server Error: ${result.data}`)
  }
}
