import { FormPopup } from 'components'
import React, { ReactNode, useState } from 'react'
import { AddInvoiceForm } from './add-invoice-form'
import { Button } from '@setter.inc/ui'
import { Flex } from 'ui-kit'

const AddInvoice = (purchaseOrderId: number): ReactNode => {
  const [isVisible, setIsVisible] = useState(false)

  const handleAddInvoiceTrigger = (
    <Flex alignItems="center">
      <Button size="middle" type="primary">
        + Upload Invoice
      </Button>
    </Flex>
  )

  const handleVisibleChange = (visible: boolean) => {
    setIsVisible(visible)
  }

  const content = (
    <AddInvoiceForm
      purchaseOrderId={purchaseOrderId}
      closeHandler={() => handleVisibleChange(false)}
    />
  )

  return (
    <FormPopup
      content={content}
      visible={isVisible}
      onVisibleChange={handleVisibleChange}
      triggerContent={handleAddInvoiceTrigger}
    />
  )
}

export default AddInvoice
