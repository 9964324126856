import { VendorAction, VendorProfile } from 'types'
import {
  GetVendorProfileRoutine,
  ResetFetchErrorRoutine,
  AddInvoiceRoutine,
} from './vendor.routines'

export interface VendorRemoteDataState {
  profile: VendorProfile
  headings: any
  fetchError: boolean | null
  loading: boolean
}

const initialState: VendorRemoteDataState = {
  profile: {
    vendorName: '',
    vendorId: 0,
    invoices: [],
    nextPaymentData: {
      amount: 0,
      startDate: '',
      endDate: '',
      paymentDate: '',
    },
  },
  headings: [],
  fetchError: null,
  loading: false,
}

const vendorReducer = (state = initialState, action: VendorAction) => {
  switch (action.type) {
    case AddInvoiceRoutine.REQUEST:
    case GetVendorProfileRoutine.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case GetVendorProfileRoutine.SUCCESS:
      return {
        ...state,
        profile: action?.payload?.data || initialState.profile,
        headings: action?.payload?.headings || [],
        fetchError: null,
        loading: false,
      }

    case AddInvoiceRoutine.FAILURE:
    case GetVendorProfileRoutine.FAILURE:
      return {
        ...state,
        fetchError: true,
        loading: false,
      }

    case AddInvoiceRoutine.SUCCESS:
      return {
        ...state,
        fetchError: false,
        loading: false,
      }

    case ResetFetchErrorRoutine.SUCCESS:
      return {
        ...state,
        fetchError: null,
        loading: false,
      }
    default:
      return state
  }
}

export default vendorReducer
