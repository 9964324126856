import { applyMiddleware, compose, createStore, Reducer } from 'redux'
import { BaseAction } from 'redux-actions'
import createSagaMiddleware from 'redux-saga'
import { rootReducer } from './rootReducer'
import rootSaga from './rootSaga'
import { VendorRemoteDataState } from './vendor/vendor.reducer'
import { AuthState } from './auth/auth.reducer'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose
  }
}

export interface ApplicationState {
  vendor: VendorRemoteDataState
  auth: AuthState
}

const composeEnhancers =
  (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  rootReducer as Reducer<ApplicationState, BaseAction>,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(rootSaga)

export default store
