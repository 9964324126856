import { combineReducers } from 'redux'
import { ApplicationState } from 'state/store'
import { VendorAction } from 'types'
import vendor, { VendorRemoteDataState } from './vendor/vendor.reducer'
import auth from './auth/auth.reducer'

const rootReducer = (state: ApplicationState, action: VendorAction) =>
  combineReducers({
    vendor: vendor as () => VendorRemoteDataState,
    auth,
  })(state, action)

export { rootReducer }
