import React from 'react'
import { styled, Box, colors, Flex } from 'ui-kit'
import { Button } from '@setter.inc/ui'
import { useDispatch } from 'react-redux'
import { SignOutRoutine } from 'state/auth/auth.routines'
import setterLogo from 'assets/setter-logo-navy.svg'

const Header = styled.header`
  background-color: ${colors.background};
  border-bottom: 2px solid ${colors.lightestGrey};
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const ButtonContainer = styled.div`
  margin: 0 6px 12px;
`

export default () => {
  const dispatch = useDispatch()
  const logOut = () => {
    dispatch(SignOutRoutine.trigger())
  }

  return (
    <Header>
      <Box my={[3]} mx={[3]} width={1 / 2}>
        <img src={setterLogo} alt={'Setter pro portal logo'} />
      </Box>
      <Flex m={1} alignItems={'flex-end'} flexDirection={'row'}>
        <ButtonContainer>
          <Button size={'large'} onClick={logOut}>
            Logout
          </Button>
        </ButtonContainer>
      </Flex>
    </Header>
  )
}
